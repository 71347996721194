import React, { useEffect } from 'react'
import propTypes from 'prop-types'
import './styles.scss'
import ReviewsYotpoInner from './reviews-yotpo-inner'
import { Box } from '@mui/material'
import { useYotpoContext } from '../../../providers/YotpoProvider'
import { refreshYotpoWidgets } from '../../../utils/yotpo'

function ReviewsYotpo({ title, yotpo_product, backgroundColor = 'gray.white' }) {
  const { yotpoReady } = useYotpoContext()

  useEffect(() => {
    refreshYotpoWidgets()
  }, [yotpoReady])

  return (
    <Box
      data-testid="reviews-yotpo-testid"
      sx={{
        backgroundColor: backgroundColor,
        overflow: 'hidden'
      }}
    >
      {yotpoReady && <ReviewsYotpoInner title={title} yotpo_product={yotpo_product} />}
    </Box>
  )
}

ReviewsYotpo.propTypes = {
  title: propTypes.string,
  show_stars: propTypes.bool,
  backgroundColor: propTypes.string,
  yotpo_product: propTypes.shape({
    name: propTypes.string,
    product_id: propTypes.string,
    url: propTypes.string,
    image_path: propTypes.string
  })
}

ReviewsYotpo.defaultProps = {
  yotpo_product: {
    name: 'Personalized Skincare System',
    product_id: '823100',
    url: 'https://www.provenskincare.com/personalized-subscription/'
  }
}

export default ReviewsYotpo
